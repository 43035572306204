import React, { Fragment } from "react"
import { SimpleSEO } from "../components/layout"
import Navbar from "../components/navbar"
import { ReactTypeformEmbed } from "react-typeform-embed"

export default () => (
  <Fragment>
    <SimpleSEO />
    <Navbar />
    <ReactTypeformEmbed url="https://dualgoodhealth.typeform.com/to/ZjeVHC" />
  </Fragment>
)
